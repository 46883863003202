import { graphql, useStaticQuery } from "gatsby";

export const SiteData = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author {
            name
            summary
          }
          description
        }
      }
    }
  `)

  return data;
}

