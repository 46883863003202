import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

/* eslint-disable react/prop-types */
const PageLink = props => (
  <span id={props.children} className="nav-link md:nav-link text-sticknobills">
    <ProcessBarLink
      to={props.to}
      activeStyle={{ backgroundSize: `100% 3px` }}
      partiallyActive={true}>
      {props.children}
    </ProcessBarLink>
  </span>
);

const ProcessBarLink = ({ children, to, activeStyle }) => {
  return (
    <Link className="process-bar left2right"
      to={to}
      activeStyle={activeStyle}
      partiallyActive={true}>
      {children}
    </Link>
  );
}

const ProcessBarReversedLink = ({ children, to, activeStyle }) => {
  return (
    <Link className="process-bar right2left"
      to={to}
      activeStyle={activeStyle}
      partiallyActive={true}>
      {children}
    </Link>
  );
}

const TagLink = ({ children, tagText, activeStyle }) => {
  let to = `/tagged/${kebabCase(tagText)}/`;
  return (
    <Link className="tag-link"
      to={to}
      activeStyle={activeStyle}
      partiallyActive={true}>
      {children}
    </Link>
  );
}

const PostLink = ({ children, to }) => {
  return (
    <Link className="article-container duration-200 ease-in-out transform hover:scale-105 hover:shadow-xl"
      to={to}>
      {children}
    </Link>
  );
}

const PostLinkExternal = ({ children, to, title }) => {
  return (
    <a className="article-container duration-200 ease-in-out transform hover:scale-105 hover:shadow-xl"
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      title={title}>
      {children}
    </a>
  );
}

export {
  PageLink,
  ProcessBarLink,
  ProcessBarReversedLink,
  TagLink,
  PostLink,
  PostLinkExternal,
}
