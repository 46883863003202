import React from "react";

const Avatar = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={props.width} height={props.height} viewBox="0 0 1190 1170"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1115.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M5650 10450 c-19 -5 -59 -12 -88 -15 -107 -10 -192 -50 -273 -127 -86 -82 -121 -185 -107 -313 9 -82 58 -210 104 -270 19 -25 25 -40 17 -42 -7 -3 -41 -27 -75 -55 -35 -27 -92 -69 -128 -92 -75 -49 -122 -93 -156 -148 -13 -21 -38 -50 -56 -66 -72 -63 -97 -99 -152 -222 -6 -14 -28 -55 -48 -91 -45 -78 -47 -95 -13 -111 14 -6 25 -17 25 -24 0 -8 12 -21 28 -31 15 -10 37 -28 49 -40 12 -13 29 -23 38 -23 8 0 15 -4 15 -10 0 -5 5 -10 11 -10 5 0 8 4 4 9 -3 5 -1 12 4 15 13 8 43 -23 35 -35 -3 -6 0 -9 7 -8 24 4 103 -22 141 -47 21 -13 41 -24 45 -24 5 0 24 -11 43 -24 20 -13 58 -31 85 -40 28 -9 69 -25 93
-36 23 -11 61 -29 85 -39 23 -10 78 -36 122 -56 44 -21 91 -41 105 -46 14 -4 48 -16 75 -27 28 -11 66 -26 85 -32 19 -7 42 -16 51 -21 9 -4 53 -15 98 -23 52 -10 90 -23 104 -36 12 -12 41 -26 65 -32 27 -7 39 -14 31 -18 -15 -10 -4 -40 15 -40 8 0 19 7 24 16 8 14 20 16 68 11 130 -14 166 -31 245 -118 36 -41 49 -50 66 -45 12 4 22 15 22 24 0 9 8 49 17 87 l18 71 89 17 c100 20 305 33 359 23 20 -3 53 -20 73 -36 49 -39 73 -39 88 0 10 26 17 30 47 30 60 0 77 -10
119 -66 23 -30 41 -58 41 -61 0 -3 11 -21 26 -40 40 -53 104 -42 104 18 0 33 18 48 63 56 26 4 40 1 54 -13 23 -22 83 -120 83 -134 0 -6 3 -10 8 -10 7 0 45 -84 49 -110 1 -8 4 -26 7 -40 3 -14 7 -36 10 -50 2 -14 7 -36 11 -50 4 -14 9 -36 11 -50 10 -50 11 -55 17 -70 3 -8 18 -76 32 -150 15 -74 32 -144 39 -155 19 -30 27 -73 16 -80 -5 -3 -10 -2 -10 4 0 40 -314 38 -515 -3 -137 -28 -166 -38 -202 -70 -28 -24 -29 -34 -3 -61 18 -19 18 -21 2 -22 -9 0 -28 -4 -42 -8 -14 -4 -32 -7 -40 -7 -8 0 -22 -2 -30 -5 -8 -4 -19 -7 -25 -7 -5 -1 -37 -6
-70 -11 -33 -5 -64 -7 -70 -6 -5 2 -34 5 -63 7 -30 2 -78 8 -108 14 -50 11 -54 14 -54 40 0 22 -7 31 -34 44 -146 69 -622 73 -841 5 -73 -22 -135 -60 -135 -84 0 -7 6 -20 14 -28 12 -11 11 -15 -6 -26 -12 -8 -24 -9 -31 -3 -7 5 -28 13 -47 17 -19 4 -39 11 -45 15 -5 4 -32 13 -60 19 -54 12 -62 15 -85 24 -20 8 -127 37 -163 44 -15 3 -26 8 -25 12 2 3 10 18 17 34 8 15 21 27 28 27 8 0 27 12 42 28 15 15 39 32 52 38 18 9 29 28 43 75 27 94 48 149 71 189 10 17 16 55 16 115 1 50 2 149 3 220 1 149 12 180 74 218 40 24 135 36 147 17 7 -11
52 -4 71 12 8 5 16 -2 24 -22 14 -34 30 -40 30 -10 0 11 -5 20 -10 20 -6 0 -3 11 6 25 9 14 22 25 29 25 15 0 24 50 14 78 -5 12 -21 22 -46 27 -21 4 -60 17 -88 30 -78 36 -147 57 -184 56 -19 -1 -41 5 -50 12 -20 16 -153 77 -169 77 -12 0 -16 2 -88 39 -23 11 -49 21 -57 21 -8 0 -17 3 -19 8 -1 4 -48 29 -103 56 -55 27 -107 53 -115 57 -8 5 -33 17 -55 28 -22 11 -60 35 -85 54 -24 20 -48 33 -52 31 -5 -3 -22 6 -39 20 -17 14 -41 29 -54 32 -14 3 -27 14 -30 24 -3 10 -19 23 -34 28 l-28 10 -33 -96 c-26 -78 -34 -121 -40 -217 -7 -121 -4
-373 5 -475 3 -30 7 -82 9 -115 2 -33 7 -76 11 -95 4 -19 8 -44 10 -55 3 -20 9 -45 21 -95 10 -45 14 -65 19 -105 11 -85 47 -242 65 -280 10 -22 24 -69 30 -105 6 -35 18 -76 26 -92 8 -15 14 -29 14 -31 0 -12 -41 -20 -115 -23 l-80 -4 -47 -50 c-34 -36 -53 -68 -65 -108 -18 -62 -25 -190 -11 -209 5 -6 8 -17 7 -25 -5 -24 12 -122 28 -158 8 -19 19 -44 24 -55 4 -11 17 -48 28 -83 20 -62 59 -145 98 -210 11 -18 59 -72 106 -121 77 -81 90 -90 132 -97 59 -8 128 1 166 23 27 16 31 16 64 0 20 -9 42 -17 50 -17 8 0 24 -18 34 -39 18 -34 21 -66
26 -267 8 -326 8 -319 -13 -366 -37 -84 -86 -144 -239 -293 -109 -106 -177 -163 -283 -235 -36 -25 -67 -47 -70 -50 -3 -3 -21 -14 -40 -24 -19 -11 -64 -35 -100 -55 -36 -20 -105 -54 -155 -75 -49 -21 -106 -45 -125 -54 -19 -8 -44 -16 -55 -18 -32 -4 -75 -23 -75 -33 0 -5 -8 -8 -19 -6 -12 2 -46 -22 -97 -68 -102 -92 -148 -127 -207 -157 -40 -21 -106 -69 -210 -155 -9 -8 -20 -15 -23 -15 -3 0 -60 -42 -127 -93 -67 -51 -140 -105 -162 -120 -22 -14 -57 -41 -77
-59 -52 -47 -70 -61 -98 -79 -14 -9 -35 -25 -47 -35 -65 -57 -179 -145 -288 -224 -42 -30 -89 -65 -104 -77 -16 -13 -32 -23 -35 -23 -3 0 -14 -8 -25 -18 -43 -40 -107 -90 -169 -130 -36 -24 -86 -60 -111 -80 -25 -20 -55 -42 -65 -48 -11 -6 -23 -17 -26 -25 -35 -77 -28 -72 -143 -95 -61 -12 -120 -30 -135 -41 -50 -36 -53 -36 -42 -9 5 14 7 26 4 26 -20 0 -46 -27 -66 -67 -12 -27 -36 -70 -51 -97 -16 -27 -39 -79 -53 -115 -13 -35 -35 -79 -49 -96 -13 -18 -42 -76 -64 -131 -22 -54 -59 -144 -82 -199 -22 -55 -50 -134 -60 -175 -11 -41 -32
-97 -49 -126 -16 -28 -36 -76 -45 -106 -8 -30 -27 -70 -40 -89 -31 -41 -48 -99 -40 -136 4 -15 8 -38 10 -49 2 -12 8 -31 15 -43 14 -27 32 -16 50 32 6 18 15 39 20 47 4 9 11 30 14 47 4 17 16 41 26 53 10 12 29 54 41 93 12 40 28 79 35 88 8 8 14 21 14 28 0 6 6 22 14 34 8 12 22 51 31 87 15 61 25 92 46 132 5 10 9 23 9 30 0 7 18 51 41 98 23 47 44 94 48 105 12 31 55 119 62 125 7 6 34
57 46 90 45 116 119 220 172 243 14 5 42 11 61 11 19 1 40 4 45 7 8 6 70 6 98 0 4 0 7 -7 7 -15 0 -13 68 -74 284 -254 84 -71 127 -114 170 -172 22 -30 62 -77 89 -105 58 -59 112 -136 155 -220 28 -55 39 -79 78 -172 8 -18 20 -48 28 -65 80 -180 108 -247 112 -267 3 -13 11 -35 18 -50 7 -14 16 -52 19 -85 5 -51 22 -115 48 -181 4 -11 15 -43 24 -70 32 -94 78 -122 98 -60 11 31 -7 170 -24 191 -5 6 -6 14 -2 17 3 4 0 13 -7 22 -11 14 -22 71 -22 115 0 8 -2 22 -5 30
-4 8 -7 20 -8 25 -7 39 -19 76 -26 81 -4 2 -6 11 -3 18 3 8 1 17 -5 21 -6 3 -11 14 -11 24 0 10 -7 24 -15 31 -9 7 -13 15 -10 18 3 3 -1 15 -8 26 -7 12 -21 40 -30 63 -9 23 -26 64 -38 90 -12 26 -27 62 -34 78 -37 88 -47 111 -56 125 -5 8 -18 33 -29 55 -43 82 -100 160 -171 237 -24 26 -62 72 -84 103 -22 30 -91 100 -153 156 -62 55 -110 104 -107 109 7 12 -75 90 -139 130 -30 19 -55 40 -56 46 0 6 29 34 65 63 35 29 67 61 70 71 3 10 16 22 28 26 23 7 107
67 153 109 15 14 33 25 40 25 7 0 17 6 21 13 4 7 40 36 78 65 39 28 77 57 85 64 9 7 38 26 65 42 28 16 79 52 115 81 36 28 97 76 135 105 39 29 77 59 85 67 8 8 67 53 130 101 63 49 128 100 145 116 16 15 59 48 95 73 36 26 72 52 80 60 63 57 109 92 149 113 85 47 126 77 211 159 70 66 86 77 88 61 2 -11 16 -32 31 -47 15 -16 42 -50 61 -78 74 -111 258 -309 395 -426 119 -102 348 -266 454 -325 18 -11 41 -24 50 -29 9 -6 63 -36 121 -67 417 -224 955 -338 1285 -272
284 57 664 405 831 764 9 19 29 62 45 95 16 33 29 66 29 73 0 7 4 20 9 30 32 58 77 264 76 349 0 35 3 63 7 63 8 0 53 -135 79 -234 38 -148 78 -312 83 -346 3 -19 8 -46 11 -60 5 -27 5 -27 35 -150 11 -47 22 -89 23 -95 1 -5 5 -14 8 -20 4 -5 7 -23 7 -40 0 -16 8 -50 16 -74 9 -24 20 -82 26 -128 5 -46 12 -88 16 -94 3 -6 8 -61 10 -121 3 -118 11 -182 25 -208 5 -8 13 -27 19 -42 9 -25 38 -39 42 -20 13 63 18 261 8 333 -11 74 -24 175 -34 260 -7 56 -54 298 -63
319 -3 8 -7 22 -9 30 -12 63 -15 77 -16 90 0 8 -5 20 -10 27 -5 7 -7 17 -3 23 4 6 2 17 -4 24 -7 8 -11 18 -10 22 3 13 -12 67 -23 81 -5 7 -7 17 -4 22 4 6 2 18 -3 28 -6 10 -20 52 -32 93 -63 218 -82 271 -114 311 -30 37 -56 44 -75 20 -14 -20 -15 -19 -17 24 -1 25 -8 58 -16 73 -13 27 -12 31 19 68 19 21 34 44 34 50 0 7 6 14 14 17 8 3 20 17 28 30 7 14 36 63 64 109 50 79 109 192 152 288 11 25 30 66 41 91 12 25 21 51 21 57 0 7 4 20 10 30 30 53 135 451 129
490 -1 4 0 9 1 12 7 13 11 79 15 230 2 91 5 165 5 165 1 0 6 36 14 117 4 42 13 64 30 81 54 54 126 231 161 400 14 65 32 132 40 148 9 16 13 29 9 29 -3 0 3 16 15 36 26 44 27 95 3 117 -14 13 -39 17 -105 17 -97 0 -103 6 -72 66 8 17 17 61 21 99 8 84 12 367 6 378 -2 5 -6 54 -7 110 -2 56 -7 129 -10 162 -7 59 -11 104 -19 215 -3 30 -7 78 -10 105 -5 38 -17 372 -18 495 0 27 -19 114 -30 140 -38 93 -84 157 -179 250 -48 47 -78 88 -109 150 -24 47 -53 98 -64 113
-24 32 -114 127 -121 127 -2 0 -23 13 -47 29 -24 16 -68 37 -98 46 -52 16 -110 37 -155 56 -38 16 -150 41 -192 42 -37 1 -43 4 -43 22 0 23 -84 110 -129 133 -35 18 -88 9 -153 -27 -60 -32 -172 -68 -235 -76 -41 -5 -43 -4 -45 23 0 15 -2 38 -3 52 -1 14 -21 63 -43 110 -23 47 -44 105 -48 130 -6 39 -17 55 -79 117 -99 97 -180 137 -266 130 -34 -2 -80 1 -103 8 -57 17 -184 25 -226 15z m2245 -3351 c3 -4 0 -28 -6 -55 -7 -29 -13 -139 -14 -271 l-2 -221 -26 -31
c-14 -17 -29 -27 -32 -21 -3 5 -2 10 3 10 10 0 34 70 39 111 6 54 -50 61 -80 10 -9 -16 -14 -31 -10 -35 3 -3 2 -6 -4 -6 -6 0 -22 -28 -37 -62 l-27 -62 20 -27 20 -27 -61 -12 c-67 -15 -154 -15 -197 -1 -2 0 3 9 11 19 13 14 28 44 32 62 1 3 7 15 14 28 7 12 10 22 6 22 -3 0 -1 6 5 13 6 8 8 17 4 20 -3 4 -2 7 4 7 6 0 14 12 19 28 10 35 8 31 28 75 53 120 66 150 66 157 0 5 9 29 20 54 11 26 19 48 19 51 1 3 7 18 16 35 23 45 20 78 -9 86 -14 3 -30 3 -36 -1 -13 -8 -40 -60 -46 -88 -2 -11 -9 -24 -16 -29 -7 -4 -10 -8 -5 -8 4 0 0 -11 -9 -24
-8 -14 -15 -27 -15 -31 1 -3 -6 -19 -14 -35 -9 -17 -13 -30 -9 -30 3 0 -4 -16 -16 -35 -12 -19 -18 -35 -15 -35 4 0 2 -6 -4 -13 -6 -8 -8 -17 -4 -20 3 -4 2 -7 -3 -7 -8 0 -30 -46 -39 -80 -1 -3 -9 -22 -18 -42 -38 -86 -45 -108 -40 -126 7 -27 -14 -37 -45 -21 -37 18 -145 99 -155 115 -4 7 -13 19 -20 26 -88 90 -163 303 -138 395 7 27 11 28 82 35 41 4 121 22 179 41 124 41 199 54 335 56 55 1 100 3 101 4 5 5 124 1 129 -4z m-1568 -61 c27 -4 67 -10 90 -13 94 -9
143 -17 251 -42 101 -24 112 -28 106 -45 -3 -11 -7 -51 -9 -89 -4 -79 -34 -182 -72 -249 -37 -65 -119 -172 -149 -195 -15 -11 -34 -27 -43 -36 -10 -8 -24 -19 -32 -22 -8 -4 -21 -11 -29 -16 -24 -14 -63 -29 -95 -36 -42 -9 -288 -16 -324 -9 -30 6 -30 7 -11 23 11 9 21 25 23 36 2 11 9 31 14 45 6 14 9 28 6 33 -2 4 1 7 8 7 7 0 9 3 6 7 -4 3 0 17 8 29 8 12 17 41 21 63 4 23 10 41 15 41 5 0 9 8 9 18 0 10 3 22 7 26 11 11 32 79 27 88 -3 4 0 8 7 8 7 0 9 3 6 7 -4 3 -3 11 1 17 11 14 41 95 45 121 1 11 6 25 9 31 4 5 10 33 13 61 7 47 6 51
-16 56 -22 6 -60 -16 -57 -32 1 -3 -2 -15 -6 -26 -5 -11 -10 -27 -14 -35 -3 -8 -6 -18 -7 -22 -2 -5 -4 -10 -5 -13 -1 -3 -3 -8 -4 -12 -1 -5 -10 -26 -20 -48 -9 -21 -19 -48 -21 -60 -2 -11 -4 -21 -6 -22 -1 -2 -3 -7 -4 -13 -1 -5 -3 -12 -4 -15 0 -3 -1 -6 0 -7 0 -2 -6 -16 -15 -32 -8 -16 -13 -33 -10 -38 3 -4 0 -8 -6 -8 -6 0 -10 -6 -9 -12 1 -7 -3 -26 -10 -43 -7 -16 -14 -38 -16 -47 -2 -9 -8 -25 -13 -35 -6 -10 -11 -22 -12 -28 -1 -5 -7 -21 -12 -35 -5 -14 -10
-36 -11 -50 -1 -14 -6 -28 -12 -32 -6 -5 -5 -12 5 -17 8 -6 12 -13 8 -16 -20 -20 -101 70 -138 155 -6 14 -17 38 -25 55 -16 36 -55 224 -60 298 -3 29 -9 68 -15 87 -5 19 -10 38 -10 41 0 7 50 23 63 21 4 -1 26 7 50 17 38 17 178 53 195 49 4 -1 14 2 22 5 45 18 205 21 287 5z m-1506 -28 c10 -6 19 -14 19 -19 0 -4 22 -46 48 -92 43 -75 114 -253 127 -319 3 -14 7 -35 9 -47 5 -23 21 -75 39 -126 13 -38 67 -77 104 -77 52 0 66 34 57 138 -4 48 -6 116 -5 151 1 36 -4 81
-10 100 -11 33 -16 287 -6 297 2 2 55 -14 118 -37 63 -22 157 -55 210 -72 l96 -31 7 -106 c12 -201 46 -312 141 -460 48 -74 128 -128 218 -146 51 -11 355 -5 401 7 22 6 50 19 61 30 12 10 50 33 85 50 67 33 219 173 240 221 6 13 23 46 38 73 15 28 34 69 41 93 8 23 19 42 24 42 6 0 7 -3 4 -6 -4 -4 9 -37 28 -73 81 -155 243 -528 252 -581 9 -55 10 -66 1 -90 -7 -20 -49 -50 -78 -55 -3 -1 -24 -7 -48 -14 -23 -6 -46 -10 -50 -7 -4 2 -34 7 -66 10 -32 3 -63 9 -70 14
-6 6 -33 14 -61 20 -39 8 -55 7 -77 -4 -33 -17 -45 -55 -29 -90 11 -26 80 -63 116 -64 11 0 40 -10 65 -21 69 -33 113 -38 188 -23 37 8 77 19 90 24 63 28 78 36 90 48 7 6 16 12 20 12 4 0 19 10 33 23 82 72 81 144 -7 333 -19 41 -34 78 -34 83 0 5 -7 14 -15 21 -8 7 -13 16 -10 20 3 5 -2 15 -10 23 -8 9 -15 20 -15 26 0 11 -49 104 -100 191 -16 28 -30 53 -30 56 0 3 27 -28 60 -69 81 -101 177 -171 295 -214 39 -14 79 -18 180 -18 117 0 136 3 190 26 l60 26 3 -101 c6
-249 -49 -549 -151 -811 -62 -163 -86 -212 -170 -355 -125 -213 -303 -395 -457 -466 -19 -8 -43 -20 -53 -25 -10 -5 -22 -7 -28 -3 -5 3 -15 1 -22 -4 -34 -27 -102 -29 -222 -6 -66 13 -127 27 -135 32 -9 5 -31 13 -50 17 -19 4 -41 11 -50 15 -8 5 -42 19 -75 31 -60 22 -302 138 -382 183 -154 86 -392 249 -499 341 -71 61 -199 189 -229 230 -25 34 -33 99 -40 326 -8 255 -39 335 -143 365 -31 9 -61 23 -65 30 -12 20 -47 17 -87 -7 -22 -14 -51 -21 -82 -21 -45 0 -51
3 -125 71 -67 62 -116 126 -139 181 -3 8 -12 29 -19 45 -8 17 -26 66 -40 110 -15 44 -30 85 -35 90 -4 6 -10 28 -13 50 -3 22 -9 54 -13 70 -12 55 -7 169 10 207 31 72 52 85 139 85 59 0 83 4 95 17 19 19 18 19 43 6z m553 -1800 c89 -95 297 -270 412 -348 221 -148 373 -232 574 -317 289 -122 353 -139 540 -140 l135 0 77 37 c43 20 109 62 147 92 l70 56 3 -38 c8 -97 -23 -293 -68 -432 -92 -281 -332 -596 -574 -756 -71 -46 -188 -105 -218 -109 -15 -2 -50 -6 -77 -10
-60 -8 -246 -8 -324 0 -134 13 -402 76 -496 115 -11 4 -36 14 -55 20 -118 43 -338 147 -469 222 -46 26 -85 48 -87 48 -3 0 -183 122 -209 142 -162 122 -372 324 -487 470 -120 150 -120 151 -67 162 37 7 124 40 127 49 2 4 10 7 18 7 8 0 23 5 34 10 11 6 28 14 38 18 70 35 172 94 257 151 116 77 98 64 205 147 81 63 300 277 300 294 0 4 18 35 40 68 22 33 40 67 40 76 0 9 7 19 16 23 10 4 14 13 10 27 -4 17 0 16 20 -9 14 -16 45 -50 68 -75z"/>
            <path d="M7307 6972 c-10 -10 -17 -25 -17 -33 0 -8 -4 -19 -8 -24 -13 -15 -54 -116 -59 -146 -5 -36 18 -54 54 -42 15 5 28 14 29 19 1 10 9 30 35 91 11 23 19 50 19 60 0 11 5 24 10 29 16 16 -5 64 -28 64 -10 0 -26 -8 -35 -18z" />
            <path d="M5933 6934 c-21 -33 -103 -291 -110 -346 -4 -39 -2 -48 15 -57 30 -16 48 -5 70 43 20 42 44 120 45 144 0 6 5 12 10 12 6 0 8 3 5 6 -4 3 0 20 7 37 7 18 14 35 14 39 1 4 4 13 6 20 19 53 22 98 8 110 -24 20 -54 16 -70 -8z" />
            <path d="M6310 6663 c-8 -4 -19 -11 -25 -17 -11 -11 -48 -99 -61 -146 -4 -14 -11 -28 -16 -31 -4 -3 -10 -16 -13 -28 -2 -13 -8 -28 -13 -35 -4 -6 -9 -24 -10 -41 -2 -24 3 -32 23 -42 23 -10 28 -8 50 15 14 15 25 32 25 39 0 7 13 41 29 75 68 150 72 160 65 181 -10 33 -25 41 -54 30z" />
            <path d="M4750 6764 c-44 -10 -70 -21 -82 -37 -24 -31 -50 -141 -50 -207 1 -129 26 -174 128 -231 30 -17 56 -35 59 -39 10 -15 94 -52 105 -45 19 12 31 56 24 89 -6 33 -85 106 -114 106 -25 0 -72 44 -75 70 -2 14 -4 30 -6 35 -3 11 10 100 18 121 3 7 20 16 39 20 29 5 37 2 55 -20 16 -20 26 -25 43 -20 29 9 28 8 32 57 3 38 -1 48 -28 76 -17 17 -36 31 -43 31 -8 0 -19 2 -27 4 -7 2 -42 -2 -78 -10z" />
            <path d="M7335 5451 c-110 -64 -581 -130 -713 -100 -56 12 -86 -6 -90 -56 -3 -36 0 -42 30 -60 29 -18 51 -20 188 -20 204 0 249 6 495 62 84 19 173 57 191 81 19 25 17 76 -4 95 -23 21 -58 21 -97 -2z" />
            <path d="M7687 4864 c-2 -2 -25 -5 -51 -6 -48 -1 -76 -17 -76 -43 0 -24 30 -32 140 -38 58 -4 130 -11 160 -17 30 -5 69 -12 85 -15 66 -11 86 -16 181 -44 402 -116 817 -402 1067 -734 31 -41 57 -78 57 -80 0 -3 16 -29 35 -59 53 -79 112 -207 150 -321 34 -105 41 -138 51 -242 3 -33 7 -65 9 -72 2 -7 0 -15 -6 -19 -11 -6 -4 -107 12 -181 5 -23 9 -73 9 -110 -1 -37 5 -124 11 -193 20 -215 17 -303 -15 -390 -15 -41 -35 -109 -43 -150 -9 -41 -24 -109 -34 -150 -62 -244 -75 -340 -46 -340 18 0 62 49 75 85 24 64 71 244 87 328 9 48 27 118 41 155 22 61 55 192 53 210 -1 4 0 25 1 47 l2 40 9 -30 c10 -34 17 -63 25 -115 3 -19 10 -53 16 -75 6 -22 12 -49 13 -60 3 -19 9 -43 21 -95 3 -14 8 -36 10 -50 3 -14 7 -34 10 -45 2 -11 37 -171 77 -356 39 -185 78 -344 86 -353 18 -21 47 -20 70 3 18 18 19 23 5 78 -8 32 -16 67 -19 78 -3 20 -10 50 -19 90 -18 76 -89 417 -93 448 -3 20 -9 37 -13 37 -5 0 -6 8 -3 19 3 10 0 22 -6 25 -5 4 -8 13 -4 21 3 9 0 21 -6 29 -6 7 -8 16 -5 19 4 3 3 14 -1 24 -4 10 -9 29 -11 43 -2 14 -6 36 -10 50 -4 14 -8 30 -9 35 -1 8 -8 43 -20 95 -1 3 -7 23 -13 45 -6 22 -14 56 -16 75 -3 19 -16 78 -29 130 -13 52 -34 145 -47 205 -12 61 -29 135 -37 165 -8 30 -17 64 -20 75 -3 11 -9 65 -14 119 -9 101 -34 214 -71 321 -38 110 -140 312 -165 328 -7 4 -13 14 -13 21 0 7 -9 21 -20 31 -11 10 -20 23 -20 28 0 10 -88 123 -145 185 -84 93 -199 202 -280 263 -49 38 -94 73 -100 77 -5 4 -17 11 -25 15 -8 4 -35 20 -60 36 -25 16 -65 40 -90 52 -25 12 -47 25 -50 28 -12 14 -176 88 -255 116 -27 9 -57 20 -65 23 -24 8 -32 11 -80 22 -25 6 -52 13 -60 17 -8 3 -22 5 -32 4 -9 -1 -19 3 -22 8 -4 6 -13 8 -21 5 -8 -3 -20 -2 -27 2 -7 5 -17 9 -23 9 -5 1 -35 4 -65 9 -60 8 -208 15 -213 10z" />
            <path d="M8005 4455 c-3 -8 -7 -22 -10 -30 -3 -9 11 -31 33 -53 51 -50 83 -94 123 -172 18 -36 57 -99 86 -142 29 -42 53 -80 53 -85 0 -4 14 -37 32 -73 18 -36 33 -67 33 -70 0 -3 6 -14 12 -26 7 -11 18 -47 26 -80 39 -160 107 -297 134 -271 24 25 14 182 -17 252 -4 11 -13 41 -19 67 -11 48 -57 151 -91 203 -11 17 -22 37 -26 45 -12 31 -39 76 -70 117 -17 23 -49 76 -70 118 -44 89 -88 143 -149 185 -48 33 -73 38 -80 15z" />
            <path d="M8915 3155 c-17 -17 -25 -36 -26 -62 0 -21 -2 -46 -4 -56 -2 -10 -6 -48 -10 -85 -4 -48 -17 -90 -47 -152 -56 -115 -65 -141 -72 -208 -13 -111 -17 -138 -31 -187 -7 -27 -16 -117 -20 -200 -12 -278 -14 -307 -23 -318 -15 -19 -21 -48 -23 -116 -1 -35 -11 -88 -22 -117 -29 -79 -13 -118 75 -181 63 -45 73 -33 47 51 -20 65 -21 70 -5 118 9 28 16 78 16 111 0 41 5 66 16 78 11 12 17 43 20 101 2 46 6 126 9 178 3 52 7 127 9 165 2 39 11 95 19 125 9 30 18 82 21 115 5 57 10 90 18 125 2 8 18 44 35 80 53 112 62 149 68 281 2 70 7 134 11 142 3 9 -2 20 -15 26 -29 16 -39 14 -66 -14z" />
        </g>
    </svg>);
export {
    Avatar,
}