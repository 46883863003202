import React from "react";
import kebabCase from "lodash/kebabCase";
import { ProcessBarLink } from "../animated/index";


const TableOfContentsWrapper = ({ children }) => {
  return (
    <div className="sticky toc-outline p-2 top-4 lg:mb-4">
      {children}
    </div>
  );
}

const TocUl = ({ children }) => {
  return (<ul className="toc-ul" >{children}</ul>);
}

const TocLi = ({ children }) => {
  return (<li className="toc-li" >{children}</li>);
}

class HeaderNode {
  constructor(value, depth) {
    this.value = value
    this.depth = depth
    this.children = []
  }

  getLink() {
    let anchor = kebabCase(this.value)
    let to = '#' + anchor
    return (
      <ProcessBarLink to={to} id={anchor}>
        {this.value}
      </ProcessBarLink>
    )
  }

  render() {
    if (this.children.length === 0 && this.value) {
      return (<TocLi key={this.value + '-li'}>{this.getLink()}</TocLi>)
    }

    var childrenRender = (
      <TocUl>
        {this.children.map(child => (child.render()))}
      </TocUl>
    )

    if (!this.value) {
      // this node is a dummy level, wrap another level
      return <TocLi>{childrenRender}</TocLi>
    }

    return (
      <TocLi>
        <p>{this.getLink()}</p>
        {childrenRender}
      </TocLi>
    )
  }
}

function _createNodes(rootDepth, headings) {
  var stack = []
  let roots = []

  for (var i = 0; i < headings.length; i++) {
    let heading = headings[i]

    if (heading.depth === rootDepth) {
      let root = new HeaderNode(heading.value, heading.depth)
      roots.push(root)
      stack = [root] // set stack with a fresh start
      continue
    }

    // this heading is not a root (depth !== 1)
    if (stack.length === 0) {
      // push a dummy root node to start
      let root = new HeaderNode(undefined, rootDepth)
      roots.push(root)
      stack = [root]
    }

    // push dummy node until its parent level
    var depth = heading.depth
    while (depth - 1 > stack[stack.length - 1].depth) {
      var parent = stack[stack.length - 1]
      var child = new HeaderNode(undefined, parent.depth + 1)
      parent.children.push(child)
      stack.push(child)
    }

    // find the parent
    while (heading.depth - 1 !== stack[stack.length - 1].depth) {
      stack.pop()
    }
    var node = new HeaderNode(heading.value, heading.depth)
    stack[stack.length - 1].children.push(node)
    stack.push(node)
  }

  return roots
}


function createToc(headings) {
  var rootDepth = undefined
  for (var i = 0; i < headings.length; i++) {
    var depth = headings[i].depth
    if (rootDepth === undefined || depth < rootDepth) {
      rootDepth = depth
    }
  }

  let nodes = _createNodes(rootDepth, headings)
  return (
    <TocUl>
      {nodes.map(node => node.render())}
    </TocUl>
  )
}

export function TableOfContents({ post }) {
  let headings = post.headings.filter(heading => heading.depth < 3)

  if (headings.length === 0) {
    return (
      <></>
    )
  }
  return (
    <TableOfContentsWrapper>
      <section id="tableOfContents">
        {createToc(headings)}
      </section>
    </TableOfContentsWrapper>
  )
}
