import React from "react";

import {
  Header,
} from "./page";

import {
  Footer
} from "./metadata/index";

const Layout = props => {

  return (
    <div className="min-h-screen layout-main">
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export { Layout }
