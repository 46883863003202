import React from "react";
import { IoSunny, IoMoon } from "react-icons/io5";
import Helmet from "react-helmet";

const COLOR_SCHEME = "tomorrow";
const LIGHT_THEME = "light-mode";
const DARK_THEME = "dark-mode";
const ANIMATED_CLASSNAME = "animated";
const LIGHT_THEME_TITLE = "Switch to Light Theme.";
const DARK_THEME_TITLE = "Switch to Dark Theme.";

export default class ThemeButton extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTheme = this.toggleTheme.bind(this);
    this.state = {
      colorScheme: COLOR_SCHEME,
      themeMode: LIGHT_THEME,
      title: DARK_THEME_TITLE,
    };
  }

  componentDidMount() {
    const curTheme = this.getTheme();
    this.setState({ themeMode: curTheme });
  }

  getTheme() {
    // To deal with Gatsby's server side rendering build check
    var theme = LIGHT_THEME
    if (typeof window !== "undefined") {
      return window.localStorage.getItem("themeMode") || theme;
    }
    return theme;
  }

  setLocalStorage(theme) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("themeMode", theme);
    }
  }

  toggleRotation(id) {
    let lightIcon = document.getElementById("light-svg");
    let darkIcon = document.getElementById("dark-svg");

    if (id === "light-svg") {
      lightIcon.classList.add(ANIMATED_CLASSNAME);
      darkIcon.classList.remove(ANIMATED_CLASSNAME);
    } else {
      darkIcon.classList.add(ANIMATED_CLASSNAME);
      lightIcon.classList.remove(ANIMATED_CLASSNAME);
    }
  }

  toggleTheme(e) {
    e.preventDefault();
    var body = document.body;

    if (body.classList.contains(DARK_THEME)) {
      this.setState({
        themeMode: LIGHT_THEME,
        title: DARK_THEME_TITLE,
      });
      this.setLocalStorage(LIGHT_THEME);
      this.toggleRotation("light-svg");
    } else {
      this.setState({
        themeMode: DARK_THEME,
        title: LIGHT_THEME_TITLE,
      });
      this.setLocalStorage(DARK_THEME);
      this.toggleRotation("dark-svg");
    }
  }

  render() {
    let {
      colorScheme,
      themeMode,
      title,
    } = this.state;

    let bodyClass = (
      <Helmet>
        <body className={`${colorScheme} ${themeMode}`} />
      </Helmet>
    );

    return (
      <span
        className="theme-slide cursor-pointer"
        onClick={this.toggleTheme}
        title={title}>
        {bodyClass}
        <div className="theme-icon">
          <IoSunny id="light-svg" size={".75em"} />
          <IoMoon id="dark-svg" size={".75em"} />
        </div>
      </span>
    );
  }
}

