import React from "react";

const OlderPage = props => (
    <svg width="35" height="21" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="older-arrow" clip-path="url(#clip0_2_2)">
            <path id="forward-arrow" d="M37.9808 7.4641C37.9808 5.9245 39.6474 4.96225 40.9808 5.73205L79.9808 28.2487C81.3141 29.0185 81.3141 30.943 79.9808 31.7128L40.9808 54.2295C39.6474 54.9993 37.9808 54.037 37.9808 52.4974L37.9808 7.4641Z" fill-opacity="0.8" />
            <path id="static-arrow" d="M13.9808 7.4641C13.9808 5.9245 15.6474 4.96225 16.9808 5.73205L55.9808 28.2487C57.3141 29.0185 57.3141 30.943 55.9808 31.7128L16.9808 54.2295C15.6474 54.9993 13.9808 54.037 13.9808 52.4974L13.9808 7.4641Z" fill-opacity="0.8" />
            <path id="back-arrow" d="M13.9808 7.4641C13.9808 5.9245 15.6474 4.96225 16.9808 5.73205L55.9808 28.2487C57.3141 29.0185 57.3141 30.943 55.9808 31.7128L16.9808 54.2295C15.6474 54.9993 13.9808 54.037 13.9808 52.4974L13.9808 7.4641Z" fill-opacity="0.8" />
        </g>
    </svg>
);

const NewerPage = props => (
    <svg width="35" height="21" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="newer-arrow" clip-path="url(#clip0_2_2)">
            <path id="forward-arrow" d="M61.9808 52.4974C61.9808 54.037 60.3141 54.9993 58.9808 54.2295L19.9808 31.7128C18.6474 30.943 18.6474 29.0185 19.9808 28.2487L58.9808 5.73205C60.3141 4.96225 61.9808 5.9245 61.9808 7.4641L61.9808 52.4974Z"  fill-opacity="0.8" />
            <path id="static-arrow" d="M84.9808 52.4974C84.9808 54.037 83.3141 54.9993 81.9808 54.2295L42.9808 31.7128C41.6474 30.943 41.6474 29.0185 42.9808 28.2487L81.9808 5.73205C83.3141 4.96225 84.9808 5.9245 84.9808 7.4641L84.9808 52.4974Z"  fill-opacity="0.8" />
            <path id="back-arrow" d="M84.9808 52.4974C84.9808 54.037 83.3141 54.9993 81.9808 54.2295L42.9808 31.7128C41.6474 30.943 41.6474 29.0185 42.9808 28.2487L81.9808 5.73205C83.3141 4.96225 84.9808 5.9245 84.9808 7.4641L84.9808 52.4974Z"  fill-opacity="0.8" />
        </g>
    </svg>
);

export {
    OlderPage,
    NewerPage,
}