import React from "react";
import { Grid } from "./Grid";

export function Container({ children }) {
  return (
    <Grid>
      <div className="col-start-2 pt-2 pb-4 fadein">
        {children}
      </div>
    </Grid>
  )
}
