import React from "react";
import { FaHamburger } from "react-icons/fa";

import { PageLink, ProcessBarLink } from "./animated/index";
import ThemeButton from "./animated/ThemeButton";
import { Grid } from "./page";

function toggleMenu(e) {
  e.preventDefault();
  var panel = document.getElementById("menu-panel");
  panel.classList.toggle("expanded");
}

const Nav = () => (
  <div id="menu-panel" className="menu-panel md:menu-panel" onClick={toggleMenu}>
    <PageLink to="/blog">Blog</PageLink>
    <PageLink to="/projects">Projects</PageLink>
    <PageLink to="/reading">Reading</PageLink>
    <PageLink to="/affiliates">Affiliates</PageLink>
  </div>
);

export function Header() {
  return (
    <div className="mt-6 mb-2">
      <Grid>
        <div className="flex justify-between items-center col-start-2">
          <div className="text-4xl text-sticknobills">
            <ProcessBarLink to="/" aria-label="Junhan">a process</ProcessBarLink>
          </div>
          <div className="flex justify-end items-center">
            <Nav />
            <div className="menu-icon md:menu-icon" onClick={toggleMenu}>
              <FaHamburger />
            </div>
            <div className="ml-4">
              <ThemeButton />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
}

