import React from "react";

import { TagLink } from "../animated/index";


export function Tags({ tags, title }) {
  if (tags === null || tags.length === 0) {
    return (
      <></>
    )
  }

  if (title === undefined) {
    title = <></>;
  }

  return (
    <div>
      {title}
      {tags.sort((a, b) => (a.fieldValue > b.fieldValue) ? 1 : -1)
        .map(tag => {
          return (
            <TagLink
              tagText={tag.fieldValue}
              key={tag.fieldValue}>
              {tag.fieldValue}
            </TagLink>
          )
        })}
    </div>
  )
}
