import React from "react";
import styled from "styled-components";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

function onVisaCheckoutReady() {
  V.init({
    apikey: 'pk_live_b7y46XGuZtzifMMmHydAGT5p000k2zHI3U',
    paymentRequest:{
      subtotal: '4.99',
      currencyCode: 'USD'
    },
    settings: {
      displayName: 'Profit Tracker Template'
    }
  });
}

function checkout() {
  console.log("clicked");
  var stripe = loadStripe('pk_live_b7y46XGuZtzifMMmHydAGT5p000k2zHI3U');

  var protocol = window.location.protocol;
  var hostname = window.location.hostname;
  var host = protocol.concat('//').concat(hostname);

  stripe.redirectToCheckout({
    items: [{sku: 'sku_GleqKSbUgJvC2w', quantity: 1}],

    // Do not rely on the redirect to the successUrl for fulfilling
    // purchases, customers may not always reach the success_url after
    // a successful payment.
    // Instead use one of the strategies described in
    // https://stripe.com/docs/payments/checkout/fulfillment
    successUrl: host.concat('/pages/payment-complete?session_id={CHECKOUT_SESSION_ID}'),
    cancelUrl: host.concat('/projects/profit-tracker-template/#download'),
  })
  .then(function (result) {
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer.
      var displayError = document.getElementById('error-message');
      displayError.textContent = result.error.message;
    }
  });
}

const stripePromise = loadStripe('pk_live_b7y46XGuZtzifMMmHydAGT5p000k2zHI3U');

const Button = styled.div`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.2rem;

  &:hover {
    color: #ECEFF4;
  }
`

const Link = styled.a`
  color: ${props => props.color};
  text-decoration: none;
`

const ColoredButton = styled(Button)`
  border-color: ${props => props.color};
  &:hover {
    background-color: ${props => props.color};
  }
`

const PurchaseButton = () => {
  return (
    <div>
      <ColoredButton color={"#A3BE8C"}>
        <Link 
          href="https://buy.stripe.com/dR67t1avp1PR1ck144">
          Purchase
        </Link>
      </ColoredButton>
    </div>
  );
}

const DownloadButton = () => {
  return (
    <div>
      <ColoredButton color={"#8FBCBB"}>Download</ColoredButton>
    </div>
  );
}

export {
  PurchaseButton,
  DownloadButton,
}
