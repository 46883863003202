import React from "react";
import { FaRegEnvelope, FaRss, FaGithub } from "react-icons/fa";
import { Grid } from "../page";

const FootIcon = props => (
  <a
    className="footer-link"
    aria-label={props.ariaLabel}
    href={props.href}
    title={props.title}>
    {props.children}
  </a>
);

export function Footer() {
  return (
    <div className="mt-4">
      <Grid>
        <div className="row-start-1 col-start-2">
          <FootIcon
            ariaLabel="Email"
            title="hi@junhan.xyz"
            href="mailto:hi@junhan.xyz">
            <FaRegEnvelope className="inline" />
          </FootIcon>
          <FootIcon
            ariaLabel="GitHub"
            title="some random stuff!"
            href="https://github.com/junhan-z">
            <FaGithub className="inline" />
          </FootIcon>
          <FootIcon
            ariaLabel="RSS"
            title="Keep it up."
            href="/rss.xml">
            <FaRss className="inline" />
          </FootIcon>
        </div>
        <div className="mt-0 col-start-2 text-xs text-sticknobills">
          © 2020-{new Date().getFullYear()}, trust the process.
        </div>
      </Grid>
    </div>
  )
}
