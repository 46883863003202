import React from "react";

import {Avatar} from "./Avatar";
import { ProcessBarLink } from "../animated/index";
import { SiteData } from "../../hooks/site-data";

const Bio = () => {
  const data = SiteData();
  const author = data.site.siteMetadata.author

  return (
    <div className="flex mb-2">
      <div className="bio-svg rounded-full">
        <Avatar width="60" height="60" />
      </div>
      <div style={{ alignSelf: `center`, marginLeft: `0.5rem` }}>
        {"Written by "}
        <ProcessBarLink
          style={{ marginLeft: `0.2rem` }}
          to="/">
          <b className="text-sticknobills">{author.name}</b>
        </ProcessBarLink>
        {`. | ${author.summary}`}
      </div>
    </div>
  );
}

export {
  Bio
}
