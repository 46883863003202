import React from "react"
import PropTypes from "prop-types";
import { SectionFlex } from "./Flex";
import { PostLink } from "./animated/index";


const ItemSeparator = ({ children }) => {
  return (
    <div className="mb-1 last-auto">
      {children}
    </div>
  );
}

const ListingItemCover = ({ coverPath }) => {
  if (coverPath) {
    return (
      <div className="relative">
        <div className="pb-24">
          <img className="absolute top-0 left-0 object-cover h-full w-full"
            src={coverPath} />
        </div>
      </div>
    );
  }

  return <></>;
}

const ListingItemPreview = ({ frontmatter }) => {
  var postDate = <></>;
  if (frontmatter.date !== undefined) {
    postDate = (
      <ItemSeparator>
        <span className="text text-quicksand text-xs">{frontmatter.date}</span>
      </ItemSeparator>
    );
  }

  return (
    <SectionFlex>
      <h3 className="mb-1 font-medium text-xl">
        {frontmatter.title}
      </h3>
      <div className="text text-quicksand text-xs">{frontmatter.excerpt}</div>
      {postDate}
    </SectionFlex>
  );
}

const ListingItemWrapper = ({ children }) => {
  return (
    <article className="mb-2">
      {children}
    </article>
  );
}

const ListingItem = ({ node }) => {
  var itemCover = <></>;

  if (node.frontmatter.coverPath) {
    let coverPath = node.frontmatter.coverPath;
    itemCover = <ListingItemCover coverPath={coverPath} />
  }

  return (
    <ListingItemWrapper>
      <PostLink to={`/${node.slug}`}>
        {itemCover}
        <ListingItemPreview frontmatter={node.frontmatter} />
      </PostLink>
    </ListingItemWrapper>
  );

}

ListingItem.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,

    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),

    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string,
      coverPath: PropTypes.string,
    }),
  })
};

export {
  ListingItem,
  ListingItemWrapper,
  ListingItemCover,
  ListingItemPreview,
}
