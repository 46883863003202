import React from "react";

function getExtraClassNames(className) {
  if (className !== undefined) {
    return className;
  }
  return "";
}

const Grid = props => {
  return (
    <div className={`grid grid-main md:grid-main ${getExtraClassNames(props.className)}`}>
      {props.children}
    </div>
  );
}

const PostsGrid = props => {
  return (
    <div className={`grid grid-posts gap-4 ${getExtraClassNames(props.className)}`}>
      {props.children}
    </div>
  );
}

export {
  Grid,
  PostsGrid,
}
