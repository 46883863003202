import React from "react";
import { OlderPage, NewerPage } from "./animated/index";
import { Link } from "gatsby";

export function PageNavigation({ currentPage, numPages, postType }) {
  if (numPages === 1) {
    return (<div></div>)
  }

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/${postType}` : `/${postType}/${currentPage - 1}`;
  const nextPage = `/${postType}/${currentPage + 1}`;

  const empty = <div></div>;
  const newer = (
    <Link to={prevPage} rel="Newer Posts">
      <NewerPage />
    </Link>
  );
  const older = (
    <Link to={nextPage} rel="Newer Posts">
      <OlderPage />
    </Link>
  );

  const leftIcon = isFirst ? empty : newer;
  const rightIcon = isLast ? empty : older;

  return (
    <div className="flex justify-between items-center">
      {leftIcon}
      <span className="text text-xs mb-0">
        {`${currentPage} of ${numPages}`}
      </span>
      {rightIcon}
    </div>
  )
}
