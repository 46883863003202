import React from "react";

const SectionFlex = ({ children }) => {
  return (
    <section className="flex flex-col h-full px-4 pt-2 pb-0">
      {children}
    </section>
  );
}

export {
  SectionFlex,
}
